@import './common/_utils';
@import './common/_fonts';

:root {
    --main-input-height: 40px;
}

body, 
#root {
    min-height: 100vh;
    margin: 0;

    .p-float-label input:focus ~ label, 
    .p-float-label input:-webkit-autofill ~ label, 
    .p-float-label input.p-filled ~ label, 
    .p-float-label textarea:focus ~ label, 
    .p-float-label textarea.p-filled ~ label, 
    .p-float-label .p-inputwrapper-focus ~ label, 
    .p-float-label .p-inputwrapper-filled ~ label, 
    .p-float-label .p-tooltip-target-wrapper ~ label 
    {
        top: -0.45rem;
    }

    .InputPasswordComponent__body .p-float-label .p-inputwrapper-focus ~ label, 
    .InputPasswordComponent__body .p-float-label .p-inputwrapper-filled ~ label, 
    .InputPasswordComponent__body .p-float-label .p-tooltip-target-wrapper ~ label
    {
        top: 0rem;
    }
}