.PickListComponent {
    &__header {
        &-error {
            display: flex;
            justify-content: center;
            color: var(--red-500);
        }
    }

    &.p-invalid {
        .p-picklist-target {
            border: 1px solid var(--red-500);;
        }
    }
}