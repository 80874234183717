.TableComponent.p-datatable {
    thead {
        tr {
            &:first-of-type {
                th {
                    background-color: initial;
                    border:  none;

                    &.p-highlight {
                        background-color: initial;
                    }
                }
            }

            th {
                &.p-filter-column {
                    background-color: white;

                    &:first-child {
                        border-top-left-radius: 24px;
                    }

                    &:last-child {
                        border-top-right-radius: 24px;
                    }

                    input {
                        border-radius: 1.5rem;
                    }

                    .p-hidden-space {
                        display: none;
                    }
                }

                .p-column-header-content {
                    justify-content: center;
                }
            }
        }
    }

    tbody {
        tr:last-of-type {
            background-color: none;
            
            td:first-of-type {
                border-bottom-left-radius: 24px;
            }
            td:last-of-type {
                border-bottom-right-radius: 24px;
            }
        }
    }

    .p-paginator {
        background-color: initial;
    }
}
