.ExamTemplateFormComponent {
    .general {
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 25px;
        padding-top: .7rem;
    }

    .navigator {
        position: sticky;
        bottom: 0.5rem;
        margin-bottom: 0.5rem;
        height: fit-content;
        width: 50px;
        background-color: #fff;
        border-radius: 5px;
        padding: 0;
        box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.18);
        -webkit-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.18);
        -moz-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.18);

        .ButtonComponent {
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
            height: 2.5em;
        }

        .add-question {
            border-top: 1px solid var(--text-color-secondary);
        }
    }
}