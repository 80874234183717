.ButtonComponent {
  width: 100%;
  height: 100%;

  button {
    width: 100%;
    height: var(--main-button-height);
    font-size: .9em;
    border-radius: 1.5rem;
  }
}