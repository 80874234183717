.InputTextComponent {
  &__body {
    .p-float-label {
      width: 100%;

      i[class] {
        + input {
          padding-left: 2.5rem;

          + label {
            left: 2.5rem;
          }
        }
      }
    }

    .p-inputtext {
      height: var(--main-input-height);
      padding-left: 12px;

      + label {
        left: 12px;
      }

      &.labelHideFilled {
        &:focus,
        &.p-filled {
          + label {
            display: none;
          }
        }
      }
    }
  }

  &__footer {
    &-error {
      color: var(--red-500);
    }
  }
}