@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.merriweather-light {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: normal;
}

.merriweather-regular {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.merriweather-black {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: normal;
}

.merriweather-light-italic {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: italic;
}

.merriweather-regular-italic {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: italic;
}

.merriweather-bold-italic {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: italic;
}

.merriweather-black-italic {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: italic;
}