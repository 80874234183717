.InputTextAreaComponent {
  &__body {
    .p-float-label {
      width: 100%;

      i[class] {
        z-index: 1;

        ~ textarea {  
          padding-left: 2.3rem;

          ~ label {
            left: 2.3rem;
          } 
        }
      }

      textarea {
        padding-left: 12px;

        ~ label {
          left: 12px;
        }
      }
    }

  }

  &__footer {
    &-error {
      color: var(--red-500);
    }
  }
}