.ExamTemplateQuestionComponent {
    border-left: 3px solid var(--primary-color);
    background-color: #fff;
    padding-bottom: 0;
    padding-top: .7rem;
    border-radius: 5px;

    &:not(:first-of-type) {
        margin-top: 15px;
    }

    .actions {
        text-align: end;
        padding-right: 15px;
        background-color: #fff;
        padding-top: 0;
        padding-bottom: 0;

        hr {
            margin-block-start: 0;
            margin-block-end: 0;
        }

        button {
            padding-top: 0;
            padding-bottom: 0;
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}