.InputPasswordComponent {
  &__body {
    .p-float-label {
      width: 100%;

      i[class] {
        z-index: 1;

        + .p-password {  
          input {
            padding-left: 2.5rem;           
          }

          + label {
            left: 2.5rem;
          } 
        }
      }
    }

    .p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
      top: -.0rem;
    }

    .p-password {
      padding-inline: 0;

      input {
        height: var(--main-input-height);
        width: 100%;
      }

      + label {
        left: 12px;
      }
    }
  }

  &__footer {
    &-error {
      color: var(--red-500);
    }
  }
}