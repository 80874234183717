.InputSelectComponent {
  &__body {
    .p-float-label {
      width: 100%;

      i[class] {
        z-index: 1;

        + .p-dropdown {  
          padding-left: 1.5rem;

          + label {
            left: 2.5rem;
          } 
        }
      }
    }

    .p-dropdown {
      padding-inline: 0;

      height: var(--main-input-height);
      padding-top: 0;

      .p-dropdown-trigger {
        margin-top: auto;
      }

      + label {
        left: 12px;
      }
    }
  }

  &__footer {
    &-error {
      color: var(--red-500);
    }
  }
}