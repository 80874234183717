.MenuComponent.p-component {
    .p-menubar-start {
        display: flex;

        .logo {
            margin-left: 1rem;
            width: 3.5rem;
            display: block;
        }

        .text {
            margin-left: .4rem;
            margin-top: auto;
            margin-bottom: auto;

            p {
                color: var(--primary-color-text);
                margin: 0;
                padding: 0;
            }
            .name {
                font-size: 1.4rem;
            }
            .slogan {
                font-size: .78rem;
            }
        }
    }

    .p-menubar-root-list {
        margin-left: auto;

        .menu-session {
            display: grid;

            .logout {
                text-align: center;
            }
        }
    }
}