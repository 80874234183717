.ProgressSpinnerComponent {  
  &.fully {
    width: 100%;
    height: 100%;
    display: flex;
    
    .p-progress-spinner {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}