.AuthenticatedBase {
    min-height: 100vh;
    background-color: var(--surface-ground);

    .MenuComponent {
        background-color: var(--primary-color);

        .p-menubar-root-list {
            > .p-menuitem {
                > .p-menuitem-link {
                    &:not(.p-disabled) {
                        &:hover {
                            background-color: transparent;
                            
                            .p-menuitem-text {
                                color: var(--primary-color-text);
                            }
                        }
        
                        &:focus {
                            box-shadow: none;
                        }
        
                        .p-menuitem-text {
                            color: var(--primary-200);
                        }
                    }
                }

                &.active {
                    .p-menuitem-link {
                        .p-menuitem-text {
                            color: var(--primary-color-text);
                        }
                    }
                }

                &.signout {
                    .p-menuitem-link {
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        color: var(--primary-200);

                        &:hover {
                            color: var(--primary-color-text);
                        }

                        &::before {
                            content: "Cerrar sesión";
                            font-size: 14px;
                        }

                        .p-menuitem-text {
                            font-weight: 600;
                            font-size: 0.9em;
                            color: var(--primary-color-text);
                        }
                    }
                }
            }
        }
    }
}