.GeneralBase {
    background-color: var(--primary-color);

    .ProgressSpinnerComponent {
        circle {
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-custom-color 6s ease-in-out infinite;
        }
    }
}

@keyframes p-progress-spinner-custom-color {
    100%, 0% {
        stroke: var(--primary-color-text);
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: var(--text-color);
    }
    80%, 90% {
        stroke: var(--text-color-secondary);
    }
}