.TimerComponent {
    display: flex;

    div {
        margin-inline: .6rem;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
        }
    
        p {
            color: var(--text-color-secondary);
            margin-top: .25rem;
            margin-bottom: 0;
        }
    }

    &--separator {
        font-size: 1.5rem;
    }
}