.ExamTemplateQuestionAnswerComponent {
    .isSelected {
        &.invalid {
            .p-checkbox {
                .p-highlight {
                    border-color: var(--red-400);
                    background: var(--red-400);
                }
            }
        }
    }

    .options {
        width: 50px;
        text-align: end;
        margin-top: auto;
        margin-bottom: auto;
    }

    .actions {
        margin-top: auto;
        margin-bottom: auto;

        button {
            padding-top: 0;
            padding-bottom: 0;
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}