.InputMaskComponent {
  width: 100%;
  padding: .5rem;

  &__body {
    .p-float-label {
      width: 100%;

      i {
        z-index: 1;
        top: 20px;

        + input {
          padding-left: 2.5rem;

          + label {
            left: 2.5rem;
          }
        }
      }

      input {
        width: 100%;
        height: var(--main-input-height);
        padding-left: 12px;

        + label {
          left: 12px;
        }
      }
    }
  }

  &__footer {
    &-error {
      color: var(--red-500);
    }
  }
}