.p-dialog {
    &.p-component {
        .p-dialog-header {
            padding-bottom: 0;
        }

        .p-dialog-content {
            padding-top: 1.5rem;
        }
    }
}