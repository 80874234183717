.LoginComponent {
    form {
        max-width: 350px;
        border-radius: 10px;
        padding-inline: 1.2em;
        background-color: var(--primary-color-text);
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);

        .header {
            margin-top: 1.2em;

            .logo {
                text-align: center;

                img {
                    border: 1px solid var(--text-color-secondary);
                    border-radius: 50%;
                    width: 150px;
                    height: 150px;
                }
            }
        }

        .MessageComponent {
            padding-bottom: 10px;

            .p-component {
                width: 100%;
            }
        }
    }
}