.TitleComponent {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  
  .ColComponent {
    padding-inline: 0;
    padding-top: 0;
  }

  &__title {
    color: var(--primary-color);

    &__goBack {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1em;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid var(--primary-color);

      i {
        padding: .15em;
      }
    }

    &__loading {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__buttons {
    margin-top: auto;
    margin-bottom: auto;

    .ButtonComponent {
      margin-left: 1em;
      width: fit-content;
    }
  }
}