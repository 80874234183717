.LoginView {
    .LoginForm {
        border-radius: var(--border-radius);
        padding: 3rem;
        background-color: var(--highlight-bg);
        color: var(--highlight-text-color);
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.18);
    }
}